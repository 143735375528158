.login {
    background: url(./assets/bg.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
}

.firebaseui-container {
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    min-height: 420px;
    padding-top: 10px;
    border-radius: 20px;
    width: 540px;
    box-shadow: none;
}
.firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 420px;
    margin-bottom: 0;
    padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}
.firebaseui-card-header .firebaseui-title {
    text-align: center;
    color: #281443;
    font-size: 31px;
    font-weight: 700;
}
.firebaseui-title {
    display: none;
}
.firebaseui-subtitle,
.firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: #5aecd3;
    box-shadow: none;
    color: #5a3888;
}
.firebaseui-form-links {
    display: none;
}
.firebaseui-id-dismiss-info-bar {
    display: block;
}
.firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
}
.mdl-shadow--2dp {
    box-shadow: none;
}
.lb-b {
    position: relative;
    top: -80px;
    left: calc(50% - 125px);
    z-index: 1000;
}
.lb-v {
    position: relative;
    top: -80px;
    right: 125px;
    z-index: 1100;
}
.lf-u {
    position: relative;
    top: -90px;
    z-index: 1200;
}
.h4-l {
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    line-height: 36px;
    color: #281443;
    z-index: 1100;
    margin-bottom: 30px;
}
.m-mwl {
    margin-top: 40px;
}
