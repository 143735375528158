.logs {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: white;
}
.logout-t {
    font-size: 14px;
    line-height: 16px;
    color: #5aecd3;
}

.nav-set {
    background: #281443;
    font-size: 14px !important;
}
.nav-pad {
    padding-top: 60px !important;
}
.dl-text {
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #281443;
    cursor: pointer;
}
.btn-diag {
    background-color: #5aecd3 !important;
    color: #5a3888 !important;
    font-weight: bold;
    font-size: 14px;
    border-color: #5aecd3 !important;
}
.btn-diag:focus {
    border-color: #5a3888 !important;
    box-shadow: 0 0 0 0.3rem rgba(90, 56, 136, 0.25) !important;
}
.dl-u {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
    position: relative;
    top: calc(50% - 12px);
}
