.devices {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: white;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: white;
}
.logout-t {
    font-size: 14px;
    line-height: 16px;
    color: #5aecd3;
}

.nav-set {
    background: #281443;
    font-size: 14px !important;
}
.nav-pad {
    padding-top: 80px !important;
}
.dl-text {
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #281443;
    cursor: pointer;
}
.dl-u {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
    position: relative;
    top: calc(50% - 12px);
}

.dl-dot {
    font-style: normal;
    font-weight: normal;
    font-size: 31px;
    color: #CBCBCD;    

}
