.ag-theme-alpine .ag-root-wrapper {
    border: none !important;
    background-color: white !important;
    border-color: '#E5E5E6' !important;
}
.ag-theme-alpine .ag-header-row {
    color: #9F9FA6 !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 11px !important;
    line-height: 13px !important;
    background-color: white !important;
    border-color: '#E5E5E6' !important;
}
.ag-row .ag-cell {
    display: flex;
    align-items: center;
}